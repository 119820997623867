import { BootstrapError } from '@/lib/managed-cache';
import { useAsyncError, useRouteError } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { useEffect } from 'react';
import { dropAllDatabases } from 'replicache';
import { ZodError } from 'zod';
import { Button } from './ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from './ui/card';
import { Hyperlink } from './ui/hyperlink';
import { Stack } from './ui/stack';

export function DefaultErrorBoundary() {
  const routeError = useRouteError();
  const asyncError = useAsyncError();
  const error = routeError || asyncError;

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  let errorMessage = 'An unexpected error occurred.';
  let errorDetails = '';
  let errorCause = '';
  let isZodError = false;

  if (error instanceof BootstrapError) {
    errorMessage = 'Failed to load application data.';
    errorCause =
      'Please make sure you are connected to the internet and try again.';
  } else if (error instanceof Error) {
    errorMessage = `${error.name}: ${error.message}`;
    errorDetails = error.stack || '';
    errorCause = String(error.cause || '');
  } else if (typeof error === 'object' && error !== null) {
    errorMessage = JSON.stringify(error);
  }

  // Check for Zod errors
  if (error instanceof ZodError) {
    errorMessage = `${error.name}: data validation failed`;
    errorDetails = JSON.stringify(error.issues, null, 2);
    isZodError = true;
  }

  return (
    <div className="h-full flex items-center justify-center p-4 overflow-auto">
      <Card className="w-full max-w-prose">
        <CardHeader>
          <CardTitle>Something went wrong.</CardTitle>
          <CardDescription>
            Constructable has encountered an error and cannot display this page.
            <br />
            Please try again and contact team@constructable.ai if you are unable
            to continue.
          </CardDescription>
        </CardHeader>
        <CardContent className="flex flex-col gap-4">
          <Stack>
            <div className="font-medium">{errorMessage}</div>
            <div className="text-sm text-gray-500">{errorCause}</div>
          </Stack>
          {errorDetails && (
            <pre className="text-sm p-4 bg-gray-100 rounded-md overflow-x-auto">
              {errorDetails}
            </pre>
          )}

          {isZodError && (
            <Button
              className="w-fit"
              onClick={() => {
                dropAllDatabases();
                window.location.reload();
              }}
            >
              Reset local database
            </Button>
          )}

          <Hyperlink to="/">Return to home page</Hyperlink>
        </CardContent>
      </Card>
    </div>
  );
}
